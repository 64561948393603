<template>
<!--    <div class="right_button_box">-->
<!--        <div class="btns">-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/recharge', query: {t: new Date().getTime()}}">입금</router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/exchange', query: {t: new Date().getTime()}}">출금</router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">고객센터</router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/notice', query: {t: new Date().getTime()}}">공지사항</router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/sports_betinfo', query: {t: new Date().getTime()}}">베팅내역</router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/culcheck', query: {t: new Date().getTime()}}">출석부</router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/event', query: {t: new Date().getTime()}}">이벤트</router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/coupon', query: {t: new Date().getTime()}}">쿠폰</router-link>-->
<!--            <router-link tag="button" class="btn-1" :to="{path: '/user_info', query: {t: new Date().getTime()}}">회원정보</router-link>-->

<!--        </div>-->

<!--    </div>-->
</template>

<script>
    export default {
        name: "RightButtonsComp"
    }
</script>

<style scoped>
    .right_button_box{
        width: 100%;
        margin-top: 8px;
        padding: 8px 5px;
    }
    .right_button_box .btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        padding: 8px 0;
    }
    .right_button_box .btns .btn-1{
        width: 24.7%;
        height: 28px;
        background-color: var(--rightBarBtnBg);
        color: #000000;
        margin: 1px 0;
        border-radius: 2px;
        font-weight: bold;
    }
    @media screen and (max-width: 1024px) {
        .right_button_box{
            --display: none!important;
        }
    }
</style>